import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import MessageDashboardList from '@/core/components/messages/MessageDashboardList.vue';
import MessageLayoutContainerSlot from '@/core/components/messages/MessageLayoutContainerSlot.vue';
import MessageProjectMiniDetailsComponent from '@/core/components/messages/MessageProjectMiniDetailsComponent.vue';
import MessagesContactsComponent from '@/core/components/messages/MessagesContactsComponent.vue';
// import CreateMicroTaskModal from '@/core/components/messages/modal/CreateMicroTaskModal.vue';
import Loader from '@/core/components/ui/Loader.vue';
import { MessageStateEnum } from '@/core/packages/shared-library';
import { MESSAGES_STORE } from '@/store/modules/messages';
import { PROPERTIES_PROJECT_POST_STORE } from '@/store/modules/project-post';
import { ATTACHMENT_CACHE_STORE } from '../../../store/modules/attachment-cache';
import { USER_MESSAGES_CACHE } from '../../../store/modules/attachment-cache/constants';
import { AUTHENTICATION_STORE } from '../../../store/modules/authentication';
import { PROJECTS_STORE } from '../../../store/modules/projects';
import { USERS_STORE } from '../../../store/modules/users';
export default defineComponent({
    name: 'messages-page',
    components: {
        MessagesContactsComponent,
        MessageDashboardList,
        MessageLayoutContainerSlot,
        MessageProjectMiniDetailsComponent,
        // CreateMicroTaskModal,
        Loader
    },
    data() {
        return {
            loadingMessageRooms: true,
            messageDashboardIsLoaded: false,
            messageDashboardSidebarIsLoaded: false,
            userMessageRooms: [],
            selectedUserMessageRoom: null,
            selectedProject: null,
            chatRooms: [],
            allDataLoaded: false,
            subscribedRoomChannels: [],
            defaultUserMessageRoom: {
                fromUser: null,
                toUser: null,
            },
            filteredUserMessageRooms: [],
            originalFilteredUserMessageRooms: [],
            isSearchTriggered: false,
            viewContactList: false,
            acceptedProjectMembers: [],
            recentlyCreatedRoom: null
        };
    },
    created() {
        this.initialize();
    },
    computed: {
        ...mapGetters(['isLoading']),
        ...mapGetters(USERS_STORE, ['user', 'hasMainLayoutInitialized']),
        ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
        ...mapGetters(['isTradesperson', 'isProjectOwner']),
        ...mapGetters(MESSAGES_STORE, ['getOpenCreateMicroTaskModal']),
        ...mapGetters(PROJECTS_STORE, [
            'currentSelectedProject',
        ]),
    },
    methods: {
        ...mapActions(['setIsLoading']),
        ...mapActions(MESSAGES_STORE, ['getUserMessageRooms', 'createUserMessage',
            'createUserMessageRoom', 'getUserMessageRoomByProjectId', 'setTotalNewMessages',
            'setUnreadUserMessagesByUserMessageRoom', 'addMemberToChatRoom', 'removeMemberFromChatRoom',
            'createUserMessageRoomAttachment', 'createUserGroupMessageRoom', 'getGroupContacts'
        ]),
        ...mapActions(PROJECTS_STORE, ['getProjectByRefId', 'setSelectedProjectJobStatus']),
        ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachment']),
        ...mapActions(PROPERTIES_PROJECT_POST_STORE, [
            'getProjectMembers',
        ]),
        navigateToProjectOverview(projectRefId) {
            this.$router.push(`/projects/${projectRefId}/project-details/overview`);
        },
        async initialize() {
            const { projectRefId } = this.$route.params;
            const { id: userId } = this.user;
            if (!this.currentSelectedProject) {
                this.navigateToProjectOverview(projectRefId);
                return;
            }
            const { currentSelectedProject } = this;
            this.selectedProject = currentSelectedProject;
            await this.getGroupContacts(userId).then((contacts) => {
                this.acceptedProjectMembers = contacts;
            }).catch(() => { });
            this.setSelectedProjectJobStatus(this.selectedProject?.projectJobStatus);
            // fetch sidebar data list
            if (this.isTradesperson && this.selectedProject) {
                this.loadingMessageRooms = true;
                const { user: loggedInUser, selectedProject } = this;
                const getUserMessageRoomsRequest = await this.getUserMessageRoomByProjectId({ userId, projectId: this.selectedProject?.id });
                let hasOwnMessageRoom = getUserMessageRoomsRequest.length;
                if (hasOwnMessageRoom) {
                    getUserMessageRoomsRequest.forEach((userMessageRoom) => {
                        if (userMessageRoom && loggedInUser) {
                            if (userMessageRoom.fromUser.id !== loggedInUser.id) {
                                hasOwnMessageRoom = false;
                            }
                        }
                    });
                }
                if (hasOwnMessageRoom) {
                    this.userMessageRooms = [{
                            ...getUserMessageRoomsRequest[0],
                            messagesIsLoaded: true,
                            members: [],
                            totalNewMessages: 0,
                        }];
                }
                else {
                    const payload = {
                        project: this.selectedProject?.id,
                        fromUser: this.user.id,
                        toUser: this.selectedProject?.projectCreator?.id,
                    };
                    await this.createUserMessageRoom({ userId: this.user.id, formData: payload })
                        .then(async () => {
                        // get again the room for better response
                        await this.getUserMessageRoomByProjectId({
                            userId: loggedInUser.id, projectId: selectedProject?.id
                        })
                            .then((response) => {
                            const createdRoomMessageRoomsRequest = response;
                            if (createdRoomMessageRoomsRequest.length) {
                                this.userMessageRooms = createdRoomMessageRoomsRequest.map((userMessageRoom) => {
                                    const extend = {
                                        ...userMessageRoom,
                                        messagesIsLoaded: true,
                                        members: [],
                                        totalNewMessages: 0,
                                    };
                                    this.subscribeRoom({ roomId: userMessageRoom.id });
                                    return extend;
                                });
                            }
                        });
                    })
                        .catch(() => { });
                }
                if (this.userMessageRooms?.length) {
                    this.filterUserMessageRooms();
                    const [firstUserMessageRoom] = this.filteredUserMessageRooms;
                    this.selectedUserMessageRoom = firstUserMessageRoom;
                    this.subscribeRoom({ roomId: this.selectedUserMessageRoom.id });
                }
                this.messageDashboardSidebarIsLoaded = true;
            }
            else if (this.isProjectOwner
            // && (this as any).selectedProject?.projectCreator?.id === (this as any).authenticatedUser?.userId
            ) {
                const getUserMessageRoomsRequest = await this.getUserMessageRoomByProjectId({ userId, projectId: this.selectedProject?.id });
                if (getUserMessageRoomsRequest?.length) {
                    this.userMessageRooms = getUserMessageRoomsRequest.map((userMessageRoom) => {
                        const extend = {
                            ...userMessageRoom,
                            messagesIsLoaded: true,
                            members: [],
                            totalNewMessages: 0,
                        };
                        const userMessageRoomId = userMessageRoom.id;
                        if (!userMessageRoom.parentId) {
                            this.subscribeRoom({ roomId: userMessageRoomId });
                        }
                        return extend;
                    });
                    this.userMessageRooms = this.userMessageRooms.filter((userMessageRoom) => !userMessageRoom.parentId);
                    this.filterUserMessageRooms();
                    if (this.recentlyCreatedRoom) {
                        const selectedRoom = this.filteredUserMessageRooms.find((room) => room.id === this.recentlyCreatedRoom.id);
                        this.selectedUserMessageRoom = selectedRoom;
                        this.recentlyCreatedRoom = null;
                    }
                    else {
                        const [firstUserMessageRoom] = this.filteredUserMessageRooms;
                        this.selectedUserMessageRoom = firstUserMessageRoom;
                    }
                }
                if (!this.filteredUserMessageRooms.length) {
                    this.selectedUserMessageRoom = null;
                }
                this.messageDashboardSidebarIsLoaded = true;
            }
            else {
                // redirect to unauthorize
                this.$router.push('/unauthorize');
            }
            this.loadingMessageRooms = false;
            this.setIsLoading(false);
        },
        subscribeRoom({ roomId }) {
            // pusher's subscribe channel `presence-messages-room-${roomId}-${toUserId}`
            const roomChannel = this.$pusher.subscribe(`presence-messages-room-${roomId}`);
            roomChannel.bind('pusher:subscription_succeeded', ({ members }) => {
                // console.info('Subscription of message room channel succeeded.');
                if (Object.values(members)) {
                    Object.values(members).forEach((member) => {
                        this.addMemberToChatRoom(member, roomId);
                    });
                }
            });
            roomChannel.bind('new.message', (message) => {
                this.appendMessageToList(message, message.userMessageRoom.id);
            });
            roomChannel.bind('new.message-attachment', (message) => {
                this.appendMessageAttachmentToList(message, message.userMessageRoom.id);
            });
            roomChannel.bind('pusher:member_added', (member) => {
                this.appendMemberToUserMessageRoom(member, roomId);
            });
            roomChannel.bind('pusher:member_removed', (member) => {
                this.removeMemberFromUserMessageRoom(member, roomId);
            });
            roomChannel.bind('pusher:subscription_error', () => {
                // console.error(error);
            });
            this.subscribedRoomChannels.push(roomChannel);
        },
        appendMemberToUserMessageRoom(member, referenceId) {
            this.addMemberToChatRoom({
                member,
                referenceId,
                userMessageRooms: this.userMessageRooms,
                callback: this.appendMemberToRoom
            });
        },
        appendMemberToRoom(foundChatRoom, member) {
            this.userMessageRooms[this.userMessageRooms.indexOf(foundChatRoom)].members.push(member);
        },
        removeMemberFromUserMessageRoom(member, referenceId) {
            this.removeMemberFromChatRoom({
                member,
                referenceId,
                userMessageRooms: this.userMessageRooms,
                callback: this.popMemberFromChatRoom
            });
        },
        popMemberFromChatRoom(findChatRoomByReference, findMemberFromProjectQuoteMembers) {
            this.userMessageRooms[this.userMessageRooms.indexOf(findChatRoomByReference)]
                .members.splice(this.userMessageRooms[this.userMessageRooms.indexOf(findChatRoomByReference)]
                .members.indexOf(findMemberFromProjectQuoteMembers), 1);
        },
        async appendMessageAttachmentToList(messageAttachmentRecord, messageRoomId) {
            const findChatRoomById = this.userMessageRooms.find((p) => p.id === messageRoomId);
            const { attachment, originalName } = messageAttachmentRecord;
            const liveAttachment = await this.getAttachment({
                name: USER_MESSAGES_CACHE,
                attachment,
                originalName,
                includeUrl: true
            });
            const newMessageAttachmentRecord = { ...messageAttachmentRecord, ...liveAttachment };
            if (findChatRoomById) {
                const findIfExists = this.userMessageRooms[this.userMessageRooms.indexOf(findChatRoomById)].userMessageAttachments.find((m) => m.id === messageAttachmentRecord.id);
                if (findChatRoomById && findChatRoomById?.id === this.selectedUserMessageRoom?.id) {
                    this.selectedUserMessageRoom.userMessageAttachments.push(newMessageAttachmentRecord);
                }
                else if (findIfExists) {
                    this.userMessageRooms[this.userMessageRooms.indexOf(findChatRoomById)].userMessageAttachments.push(newMessageAttachmentRecord);
                }
            }
        },
        async appendMessageToList(messageRecord, roomId) {
            const findChatRoomById = this.userMessageRooms.find((p) => p.id === roomId);
            if (findChatRoomById) {
                const findIfExists = this.userMessageRooms[this.userMessageRooms.indexOf(findChatRoomById)].userMessages.find((m) => m.messageId === messageRecord.messageId);
                if (!findIfExists) {
                    this.userMessageRooms[this.userMessageRooms.indexOf(findChatRoomById)].userMessages.push(messageRecord);
                }
            }
            if (this.selectedUserMessageRoom && messageRecord?.fromUser.id !== this.authenticatedUser?.userId) {
                this.selectedUserMessageRoom.totalNewMessages = this.getAllUnreadMessages();
                this.setTotalNewMessages(this.getAllUnreadMessages());
            }
        },
        async selectUserMessageRoom(userMessageRoom) {
            if (userMessageRoom) {
                this.selectedUserMessageRoom = userMessageRoom;
                this.loadUnreadMessages();
            }
        },
        async loadUnreadMessages() {
            const totalUnreadMessages = this.getAllUnreadMessages();
            const totalSelectedUserMessageRoomUnreadMessages = this.selectedUserMessageRoom.userMessages
                .filter((message) => message.state === MessageStateEnum.UNREAD && message.fromUser.id !== this.authenticatedUser?.userId);
            if (totalUnreadMessages && totalSelectedUserMessageRoomUnreadMessages.length) {
                await this.setUnreadMessagesList();
            }
            this.selectedUserMessageRoom.totalNewMessages = totalUnreadMessages;
            this.setTotalNewMessages(this.getAllUnreadMessages());
        },
        async setUnreadMessagesList() {
            const unreadMessagesList = await this.setUnreadUserMessagesByUserMessageRoom({ userId: this.user.id, roomId: this.selectedUserMessageRoom.id });
            this.selectedUserMessageRoom.userMessages = unreadMessagesList;
        },
        getAllUnreadMessages() {
            let total = 0;
            if (this.userMessageRooms && this.userMessageRooms.length) {
                this.userMessageRooms.forEach((room) => {
                    if (room) {
                        total += room?.userMessages.reduce((acc, message) => {
                            return message.state === MessageStateEnum.UNREAD && message?.fromUser?.id !== this.authenticatedUser?.userId ? acc + 1 : acc;
                        }, 0);
                    }
                });
            }
            return total;
        },
        async saveNewMessage(payload) {
            const { userId } = this.authenticatedUser;
            const formData = {
                ...payload,
                projectId: this.selectedProject?.id,
            };
            let messageRecord = null;
            await this.createUserMessage({ userId, formData })
                .then(async (response) => {
                // console.log('response', response, this.selectedUserMessageRoom);
                if (response?.id) {
                    await this.initialize().then(() => {
                        messageRecord = response;
                    });
                }
                // (this as any).$notify.error({
                //   title: 'Error Messages',
                //   message: 'Message has not been successfully sent. Please try again.'
                // });
            });
            return messageRecord;
        },
        filterUserMessageRooms() {
            const { userMessageRooms } = this;
            // const projectQuoteCreatorIds = this.getAllProjectQuoteCreatorIds();
            const filteredRooms = [];
            if (userMessageRooms && userMessageRooms.length) {
                userMessageRooms.forEach((userMessageRoom) => {
                    // const { fromUser, userMessages } = userMessageRoom as any;
                    // if (!userMessages.length && this.isProjectOwner) {
                    //   // check if it's a quoter
                    //   if (projectQuoteCreatorIds.includes(fromUser.id)) {
                    //     filteredRooms.push(userMessageRoom);
                    //   }
                    // } else {
                    filteredRooms.push(userMessageRoom);
                    // }
                });
            }
            this.filteredUserMessageRooms = filteredRooms;
            this.originalFilteredUserMessageRooms = filteredRooms;
        },
        getAllProjectQuoteCreatorIds() {
            const { selectedProject } = this;
            const { projectQuotes } = selectedProject;
            let ids = [];
            if (projectQuotes && projectQuotes.length) {
                ids = projectQuotes.map((projectQuote) => {
                    return projectQuote.projectQuoteCreator.id;
                });
            }
            return ids;
        },
        getAllTpMessageRoomUserId() {
            const { userMessageRooms } = this;
            let ids = [];
            if (userMessageRooms && userMessageRooms.length) {
                ids = userMessageRooms.map((userMessageRoom) => {
                    return userMessageRoom.fromUser?.id;
                });
            }
            return ids;
        },
        onChangeFilterUserMessageRooms(keyword) {
            this.filteredUserMessageRooms = this.originalFilteredUserMessageRooms;
            const { filteredUserMessageRooms: list } = this;
            const searchedKeyword = keyword.toLowerCase();
            const newList = [];
            if ((list && list.length) && searchedKeyword) {
                list.forEach((item) => {
                    const { fromUser, project } = item;
                    const { firstName, lastName } = fromUser;
                    const { refId, name } = project;
                    if (firstName.toLowerCase().indexOf(searchedKeyword) > -1
                        || lastName.toLowerCase().indexOf(searchedKeyword) > -1
                        || refId.toLowerCase().indexOf(searchedKeyword) > -1
                        || name.toLowerCase().indexOf(searchedKeyword) > -1) {
                        newList.push(item);
                    }
                });
            }
            if (newList.length || searchedKeyword) {
                this.filteredUserMessageRooms = newList;
            }
            if (this.filteredUserMessageRooms.length) {
                const [firstUserMessageRoom] = this.filteredUserMessageRooms;
                this.selectedUserMessageRoom = firstUserMessageRoom;
            }
            this.isSearchTriggered = !(this.filteredUserMessageRooms.length > 0);
        },
        doSubmitUploadedAttachment(file, cb) {
            const { userId } = this.authenticatedUser;
            const userMessageRoomId = this.selectedUserMessageRoom?.id;
            this.createUserMessageRoomAttachment({ userId, userMessageRoomId, file })
                .then((response) => {
                if (response) {
                    // (this as any).selectedUserMessageRoom.userMessageAttachments.push(response);
                    cb(response);
                }
            })
                .catch((e) => {
                this.$notify.error({
                    title: 'Error Uploading File Attachment',
                    message: e ? e?.response?.message : 'File attachment has not been successfully sent. Please try again.'
                });
            });
        },
        viewContacts() {
            this.viewContactList = !this.viewContactList;
        },
        async onSaveUserRoom(value) {
            const { user, currentSelectedProject } = this;
            const formData = {
                project: currentSelectedProject.id,
                fromUser: user.id,
                toUser: value
            };
            await this.createUserMessageRoom({ userId: user.id, formData }).then(async (userRoom) => {
                this.recentlyCreatedRoom = userRoom;
                await this.initialize();
            });
        },
        async onSaveGroupUserRoom(values) {
            const { user, currentSelectedProject } = this;
            const formData = {
                projectId: currentSelectedProject.id,
                users: [],
                groupName: '',
                groupAvatar: ''
            };
            values.forEach((item) => {
                formData.users.push(item.userId);
                if (!formData.groupName) {
                    formData.groupName = item.groupName;
                }
                if (!formData.groupAvatar) {
                    formData.groupAvatar = item.groupAvatar;
                }
            });
            await this.createUserGroupMessageRoom({ userId: user.id, formData }).then(async (userRoom) => {
                this.recentlyCreatedRoom = userRoom;
                await this.initialize();
            });
        }
    },
});
